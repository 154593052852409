import React from 'react';
import Hero from '../../components/hero/hero';
import Layout from '../../components/layout/layout';
import styles from "./style.module.scss";
import {isMobile} from 'react-device-detect';

import MyAccordion from "../../components/accordion/accordion";
import { BASENAME } from "../../constant";

const faqList = [
    {
        title: "Dove vi trovate?",
        content: "Ci troviamo a Cormano in Via Pietro Nenni 8 e a Cologno Monzese in via per Imbersago 19."
    },
    {
        title: "Posso acquistare da voi anche se sono distante?",
        content: "Certamente. È possibile gestire l’acquisto da remoto con uno dei nostri consulenti di vendita. Faremo trovare l’auto acquistata presso la sede che preferisci."
    },
    {
        title: "Dove posso trovare le auto in vendita?",
        content: "Puoi visionare le nostre auto in vendita nell’apposita pagina del nostro sito: <a href='https://www.gruppomilanoauto.it/veicoli'>https://www.gruppomilanoauto.it</a>. Puoi aiutarti nella ricerca impostando i filtri."
    },
    {
        title: "Sono un privato, posso comunque noleggiare l’auto?",
        content: "Sì, anche i clienti privati possono aderire al Noleggio a Lungo Termine."
    },
    {
        title: "Quali documenti servono per richiedere un finanziamento?",
        content: "<li>se sei un privato: carta d'identità e codice fiscale fronte e retro del proprietario, leggibili ed in corso di validità; ultima busta paga e codice iban.</li><li>per una ditta individuale: carta d'identità e codice fiscale fronte e retro del legale rappresentante, leggibili ed in corso di validità; una visura camerale aggiornata (ultimi 6 mesi), il modello Unico personale, le ricevute di presentazione telematica e il codice iban.</li><li>per una società: carta d'identità e codice fiscale fronte e retro del legale rappresentante, leggibili ed in corso di validità; una visura camerale aggiornata (ultimi 6 mesi), l'ultimo bilancio depositato, completo di note integrative, il modello Unico della società, le ricevute di presentazione telematica e il codice iban.</li>"
    }
]

export default function Faq() {
    return (
        <Layout>
            <Hero
                image={"bg_hero_about_us.png"}
                title={"FAQ"}
                subtitle={isMobile ? null :"Per qualunque dubbio consulta le domande frequenti o contattaci."}
                noLineHeight={true}
                mini={true}
                breadcrumb={[
                    { label: "Home", link: BASENAME },
                    { label: "FAQ", link: null }
                ]}
            />

            <section style={isMobile? {maxWidth: '1320px', marginLeft:20, marginRight:20, marginBottom:22} : {maxWidth: '1320px'}} className={isMobile ? null: 'mx-auto'}>
                <MyAccordion data={faqList}/>
            </section>

        </Layout>
    );
}

