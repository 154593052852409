import React from 'react';
import styles from "./style.module.scss";
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrow from "../../img/arrow-down-circle.png";
import {isMobile} from 'react-device-detect';

const AccordionItem = ({ header, ...rest }) => (
    <Item
        {...rest}
        header={
            <>
                {header}
                <img src={arrow} className={styles.chevron}/>
            </>
        }
        className={styles.item}
        buttonProps={{
            className: ({ isEnter }) =>
                `${styles.itemBtn} ${isEnter && styles.itemBtnExpanded}`
        }}
        contentProps={{ className: styles.itemContent }}
        panelProps={{ className: styles.itemPanel }}
    />
);


export default function MyAccordion(props) {

    return (
        <Accordion transition transitionTimeout={250} allowMultiple={true} style={isMobile ? {marginTop:-45} : null}>
            {props.data.map((el, index) => {
                return (
                    <AccordionItem key={"accordion-" + index} header={el.title}>
                        <div dangerouslySetInnerHTML={{ __html: el.content }} />
                    </AccordionItem>
                )
            })}
        </Accordion>
    );
}

