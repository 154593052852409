import React from 'react';
import Hero from '../../components/hero/hero';
import Layout from '../../components/layout/layout';

import styles from "./style.module.scss";
import { BASENAME } from "../../constant";


export default function Terms() {


    return (
        <Layout>
            <Hero
                image={"bg_hero_brands.png"}
                title={"Termini e condizioni"}
                                breadcrumb={[
                    { label: "Home", link: BASENAME },
                    { label: "Termini e condizioni", link: null }
                ]}

            />
            <section>
                <div className="container">
                    <a href="https://www.iubenda.com/termini-e-condizioni/29122716" class="iubenda-nostyle iubenda-noiframe iubenda-embed iub-no-markup iubenda-noiframe iub-body-embed" title="Termini e Condizioni">Termini e Condizioni</a>
                </div>
            </section>

        </Layout>


    );
}

