import React from 'react';
import Layout from '../../components/layout/layout';
import Hero from "../../components/hero/hero";
import Button from "../../components/button/button";

import styles from "./style.module.scss";
import { BASENAME } from "../../constant";



export default function LongTerm() {


    return (
        <Layout>
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle}>Noleggio auto a lungo termine</h1>
                <Hero
                    onlyBreadcrumb={true}
                    breadcrumb={[
                        { label: "Home", link: BASENAME },
                        { label: "Noleggio", link: null },
                        { label: "Noleggio auto a lungo termine", link: null }
                    ]}
                />

                <div className={styles.content}>
                    <div className="d-lg-none">
                        <img src="site_images/long_tem_mobile.png" className="w-100" style={{ marginBottom: 24 }} />
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 pe-lg-5">
                            <div className={styles.contentText}>
                                <div className={styles.paragraph}>
                                    Il Noleggio a Lungo Termine cerca di rispondere in particolare alle esigenze delle aziende e dei liberi professionisti.<br/>
                                                                        Negli ultimi anni però anche i privati si sono affidati sempre di più al Noleggio a Lungo Termine per i vantaggi che offre.<br/>
                                                                        È una soluzione di mobilità che mira a risparmiare denaro ma soprattutto ad eliminare il tempo dedicato alla gestione dell’auto o della flotta aziendale.
                                    </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Contratto su misura</h2>
                                        <p>
                                            La percorrenza e la durata contrattuale del tuo Noleggio a Lungo Termine, è modulabile in base alle tue necessità.
Con il tuo consulente dedicato di Gruppo Milanoauto puoi anche valutare un Anticipo ZERO e la permuta della tua auto.
                                            </p>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Previsione dei costi</h2>
                                        <p>
Ottieni un costo preciso del tuo parco auto che non può essere influenzato dal valore futuro del mezzo o da episodi imprevisti.                                             </p>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Finanziamento dei costi</h2>
                                        <p>
Il canone di noleggio comprende una totalità di servizi o di oneri tributari che in questa maniera vengono dilazionati.                                             </p>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Gestione esternalizzata</h2>
                                        <p>
Il personale dell’azienda non deve occuparsi della gestione della flotta aziendale, questo comporta una riduzione dei costi amministrativi.
Tutte le manutenzioni, le scadenze e la gestione di eventuali imprevisti, sono affidati a terzi.
                                             </p>
                                    </div>
                                </div>
                                <br/>
                                <div className={styles.paragraph}>
Il Noleggio a Lungo Termine comporta un’unica rata fissa mensile, comprensiva di una copertura assicurativa completa, della manutenzione ordinaria e straordinaria dell’auto.
In caso di sinistro non devi pensare a nulla e puoi contare sulla detrazione dell’IVA e
molti altri vantaggi.
                                     </div>


                                <div className="d-flex justify-content-center justify-content-lg-start">
                                    <Button
                                        label={"02 2540348"}
                                        variation={"4"}
                                        style={{ marginBottom: 40, marginTop: 16, marginRight: 16 }}
                                        iconRight={["fas", "phone"]}
                                        onClick={()=>window.open('tel:022540348')}
                                    />
                                    <Button
                                        iconOnly={true}
                                        icon={["fab", "whatsapp"]}
                                        variation={"tertiary"}
                                        style={{ marginBottom: 40, marginTop: 16, width:60 }}
                                        onClick={() => window.open('https://wa.me/message/6SDAOF2KJBZNE1')}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="col-12 col-lg-6 ps-lg-5 d-none d-lg-block">
                            <img src="./site_images/long-term.png" className={styles.image} />
                        </div>
                    </div>
                </div>

            </div>

        </Layout>
    );
}

