import React, { useState, useEffect, useRef } from 'react';
import Hero from '../../components/hero/hero';
import Layout from '../../components/layout/layout';
import Button from '../../components/button/button';

import styles from "./style.module.scss";
import CarPreview from "../../components/carPreview/carPreview";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ajaxGet, ajaxPost } from "../../helper/ajax";
import { useLocation } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { FilterMobile } from './filterMobile.js';

import { COOKIE_BOOKMARKS } from "../../constant"
import { getCookieForBookmark } from "../../helper/functions";
import {isMobile} from 'react-device-detect';

export default function Listing() {
    const location = useLocation();

    const [count, setCount] = useState(0);
    const [pageLoaded, setPageLoaded] = useState(false);

    const [carList, setCarList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    const [filterList, setFilterList] = useState({});

    const [carName, setCarName] = useState(location.state ?.search ? location.state.search:"");
    const [carType, setCarType] = useState(location.state ?.type ? location.state.type : null);
    const [carBrand, setCarBrand] = useState(location.state ?.brand ? location.state.brand : null);
    const [carModel, setCarModel] = useState(location.state ?.model ? location.state.model : null);
    const [carBody, setCarBody] = useState(null);
    const [carFuel, setCarFuel] = useState(null);
    const [carYear, setCarYear] = useState({ from: null, to: null });
    const [carTransmission, setCarTransmission] = useState([]);
    const [carKm, setCarKm] = useState({ from: null, to: null });
    const [carColor, setCarColor] = useState(null);
    const [maxPrice, setMaxPrice] = useState(location.state ?.maxPrice ? location.state.maxPrice : null);
    const [orderBy, setOrderBy] = useState("Ultimi Arrivi");
    const itemsPerPage = 9;

    //const [searchVersion, setSearchVersion] = useState(0);


    const mobileFilter = useRef(null);

    useEffect(() => {          
        search();
        if (Object.keys(filterList).length == 0) {
            ajaxGet("getFilters.php").then((response) => {
                if (response.code == "100") {
                    setFilterList(response.message);
                }
            });
        }

    }, [carBrand, carName, carType, carModel, carBody, carFuel, carYear, carTransmission, carKm, carColor, currentPage, maxPrice, orderBy])

    function search() {
        let formdata = new FormData();
        formdata.append("search", carName)

        if (carBrand) { formdata.append("brand", carBrand) }
        if (carType ) { formdata.append("type", carType) }
        if (carModel) { formdata.append("model", carModel) }
        if (carName) { formdata.append("name", carName) }
        if (carBody) { formdata.append("body", carBody) }
        if (carFuel) { formdata.append("fuel", carFuel) }
        if (carYear.from) { formdata.append("year_from", carYear.from) }
        if (carYear.to) { formdata.append("year_to", carYear.to) }
        if (carKm.from) { formdata.append("km_from", carKm.from) }
        if (carKm.to) { formdata.append("km_to", carKm.to) }
        if (carColor) { formdata.append("color", carColor) }
        if (maxPrice) { formdata.append("max_price", maxPrice) }
        if (orderBy) { formdata.append("order_by", orderBy)}
        if (carTransmission.length > 0) { formdata.append("transmission", carTransmission) }

        formdata.append("limit", itemsPerPage);
        formdata.append("offset", currentPage * itemsPerPage);

        ajaxPost("getVclList.php", formdata).then((response) => {

            if (response.code == "100") {
                setCarList(response.message.car);
                setCount(response.message.total);
                setPageLoaded(true)
            }
        });
    }

    function loadingPage() {
        console.log("v2");
        setCarList([]);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        setTimeout(() => {
            window.scroll({ top: -1, left: 0, behavior: "smooth" });
        }, 10); 

        setPageLoaded(false)
    }

    function renderPagination(totCars) {
        if (count == 0) { return null }
        let numberOfPages = Math.ceil(count / itemsPerPage);
        
        if (currentPage >= numberOfPages) { setCurrentPage(0) }

        let arr = [];
        arr.push(<button key={"pagination-prev"} className={styles.paginationPrev} onClick={() => {
            if (currentPage == 0) { return }
            setCurrentPage(currentPage - 1)
            loadingPage();
        }}>
            <img src="site_images/icon/icon-left-arrow.png"/>
        </button>);
        
        let showEmpty = true;
        for (let i = 0; i < numberOfPages; i++) {
            let showPage = false;

            if (Math.abs(currentPage - i) < 3) { showPage = true; }
            if (i == 0 || i == numberOfPages - 1) { showPage = true; }

            if (showPage) {
                arr.push(<button key={"pagination-" + i} className={(currentPage == i) ? styles.paginationPageActive : styles.paginationPage} onClick={() => {
                    if (currentPage == i) { return }
                    setCurrentPage(i);
                    loadingPage();
                }}>
                    {i + 1}
                </button>);
                showEmpty = true;
            } else {
                if (showEmpty) {
                    arr.push(<button key={"pagination-" + i} className={styles.paginationPage}>...</button>);
                    showEmpty = false;
                }
            }
        }

        arr.push(<button key={"pagination-next"} className={styles.paginationNext} onClick={() => {
            if (currentPage == numberOfPages - 1) { return }
            setCurrentPage(currentPage + 1)
            loadingPage();
        }}>
            <img src="site_images/icon/icon-right-arrow.png" />
        </button>);

        if (arr.length <= 2) {
            return null;
        }

        return (
            <div className={styles.paginationContainer}>
                {arr}
            </div>
        );
    }

    function renderYearOption(from = true) {
        let arr = []
        let lastYear = new Date().getFullYear()
        for (let i = 0; i < 100; i++) {
            if (!from) {
                if (carYear.from) {
                    if (carYear.from > lastYear) {
                        break;
                    }
                }
            } else {
                if (carYear.to) {
                    if (carYear.to < lastYear) {
                        lastYear--;
                        continue;
                    }
                }

            }

            arr.push(<option value={lastYear}>{lastYear}</option>)
            lastYear--;
        }

        if (arr.length == 0) { return null }
        return arr;
    }

    function renderKmOption(from = true) {
        let arr = []
        let kmArr = [2500, 5000, 10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 125000, 150000, 175000, 200000];

        for (let i = 0; i < kmArr.length; i++) {
            if (!from) {
                if (carKm.from) {
                    if (carKm.from > kmArr[i]) {
                        continue;
                    }
                }
            } else {
                if (carKm.to) {
                    if (carKm.to < kmArr[i]) {
                        continue;
                    }
                }

            }

            arr.push(<option value={kmArr[i]} >{kmArr[i].toLocaleString("it-IT") + " km"}</option>)
        }

        if (arr.length == 0) { return null }
        return arr;
    }

    function renderFilters() {
        return (
            <div className={styles.filterContainer}>
                <input className="w-100" placeholder="Cerca" onBlur={(val) => { setCarName(val.target.value); }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.target.blur();
                        }
                    }}
                />

                <h4>Tipologia</h4>
                <select required className="w-100" value={carType} onChange={(val) => setCarType(val.target.value)} >
                    <option value={""} selected>Tutto</option>
                {filterList.type ? filterList.type.map((el) => {
                
                        return (<option value={el}>{el.toUpperCase()}</option>)
                    })
                        : null
                    }
                </select>

                <h4>Marca</h4>
                <select required className="w-100" value={carBrand} placeholder="Cerca" onChange={(val) => { setCarBrand(val.target.value); setCarModel(""); }} >
                    <option value={""} selected>Tutto</option>
                    {filterList.brand ? Object.keys(filterList.brand).map((el) => {
                        return (<option value={el}>{el}</option>)
                    })
                        : null
                    }
                </select>

                <h4>Modello</h4>
                <select required className="w-100" value={carModel} onChange={(val) => { setCarModel(val.target.value); }} >
                    <option value={""} selected>Tutto</option>
                    {filterList.brand && carBrand && filterList.brand[carBrand] ?
                        filterList.brand[carBrand].map((el) => {
                            return (<option value={el}>{el}</option>)
                        })
                        : null
                    }
                </select>

                <h4>Carrozzeria</h4>
                <select required className="w-100" onChange={(val) => setCarBody(val.target.value)} >
                    <option value={""} selected>Tutto</option>
                {filterList.body ? filterList.body.map((el) => {
                    if (!el) { return}
                        return (<option value={el}>{el.toUpperCase()}</option>)
                    })
                        : null
                    }
                </select>

                <h4>Carburante</h4>
                <select required className="w-100" onChange={(val) => setCarFuel(val.target.value)} >
                    <option value={""} selected>Tutto</option>
                {filterList.fuel ? filterList.fuel.map((el) => {
                    if (!el) { return }
                        return (<option value={el}>{el.toUpperCase()}</option>)
                    })
                        : null
                    }
                </select>

                <h4>Anno</h4>
                <div className="row">
                    <div className="col-6">
                        <select required className="w-100" value={carYear.from} onChange={(val) => setCarYear({ from: val.target.value, to: carYear.to })} >
                            <option value={""} selected>Dal</option>
                            {renderYearOption()}
                        </select>
                    </div>
                    <div className="col-6">
                        <select required className="w-100" value={carYear.to} onChange={(val) => setCarYear({ from: carYear.from, to: val.target.value })} >
                            <option value={""} selected>Al</option>
                            {renderYearOption(false)}
                        </select>
                    </div>
                </div>

                <h4>Cambio</h4>
                <div className="row">
                {
                    filterList.transmission ? filterList.transmission.map((el) => {
                        if (!el) { return }
                            return (
                                <div className="col-auto">
                                    <input type="checkbox"
                                        name="transmission"
                                        value={el}
                                        onChange={(val) => {
                                            let temp = [...carTransmission];
                                            if (temp.includes(val.target.value)) {
                                                if (!val.target.checked) {
                                                    temp.splice(temp.indexOf(el), 1)
                                                }
                                            } else {
                                                if (val.target.checked) {
                                                    temp.push(el)
                                                }
                                            }
                                            setCarTransmission(temp);
                                        }}
                                    />
                                    {el[0].toUpperCase() + el.slice(1)}
                                </div>
                            )
                        }) : null
                }
                </div>

                <h4>Chilometri</h4>
                <div className="row">
                    <div className="col-6">
                        <select required className="w-100" value={carKm.from} onChange={(val) => setCarKm({ from: val.target.value, to: carKm.to })} >
                            <option value={""} selected>Da</option>
                            {renderKmOption()}
                        </select>
                    </div>
                    <div className="col-6">
                        <select required className="w-100" value={carKm.to} onChange={(val) => setCarKm({ from: carKm.from, to: val.target.value })} >
                            <option value={""} selected>A</option>
                            {renderKmOption(false)}
                        </select>
                    </div>
                </div>


                <h4>Colore</h4>
                <select required className="w-100" onChange={(val) => setCarColor(val.target.value)} >
                    <option value={""} selected>Tutto</option>
                {filterList.color ? filterList.color.map((el) => {
                    if (!el) { return }
                        return (<option value={el}>{el.toUpperCase()}</option>)
                    })
                        : null
                    }
                </select>

                <h4>Prezzo Massimo</h4>
                <select required className="w-100" value={maxPrice} onChange={(val) => setMaxPrice(val.target.value)} >
                    <option value={""} selected>Fino a</option>
                    <option value={10000}>10.000 €</option>
                    <option value={15000}>15.000 €</option>
                    <option value={20000}>20.000 €</option>
                    <option value={25000}>25.000 €</option>
                    <option value={30000}>30.000 €</option>
                    <option value={35000}>35.000 €</option>
                    <option value={40000}>40.000 €</option>
                    <option value={50000}>50.000 €</option>
                    <option value={60000}>60.000 €</option>
                    <option value={70000}>70.000 €</option>
                </select>
            </div>
        
        )
    }

    function renderMobileFilterButton() {
        return (
            <div>
                {(carName) ? singleMobileFilterButton(carName, setCarName, "") : null}
                {(carBrand) ? singleMobileFilterButton(carBrand, setCarBrand, null) : null}
                {(carType) ? singleMobileFilterButton(carType, setCarType, null) : null}
                {(carModel) ? singleMobileFilterButton(carModel, setCarModel, null) : null}
                {(carBody) ? singleMobileFilterButton(carBody, setCarBody, null) : null}
                {(carFuel) ? singleMobileFilterButton(carFuel, setCarFuel, null) : null}

                {(carColor) ? singleMobileFilterButton(carColor, setCarColor, null) : null}
                {(maxPrice) ? singleMobileFilterButton(maxPrice, setMaxPrice, null) : null}
            </div>
        )
    }

    function singleMobileFilterButton(val, resetFunction, resetValue) {
        return (
            <button className={styles.mobileFilterButton}>
                {val}
                <img src="./site_images/icon/icon-close.png" onClick={() =>resetFunction(resetValue)} />
            </button>
            )
    }


    function checkBookmark(carId) {
        // verificare se il cookie esiste
        var cookieValue = getCookieForBookmark(COOKIE_BOOKMARKS+"_"+carId);
        // ritorna true o false in base al valore (null → false)
        if (cookieValue === true) {
            //console.log(`Return car → ${carId} as Bookmarked`);
            return true
        } else {
            return false
        }
    }

    return (
        <Layout>
            <section>
                <div className="container">
                    <div className="row justify-content-between align-items-center" style={isMobile ? {marginBottom:-80} : null}>
                        <div className="d-block d-md-none col-12">
                            <div className={styles.topbarFilterMobile}>
                                <input
                                    className="w-100"
                                    placeholder="Cerca"
                                    onBlur={(val) => { setCarName(val.target.value); }}
                                    onKeyUp={(val) => { if (val.key == "Enter") { setCarName(val.target.value) } }}
                                />
                                <img src="./site_images/icon/Icon_filters.png" width={32} height={28} onClick={() => mobileFilter.current()} />
                            </div>
                        </div>

                        <div className="col" >
                            <h2 className="mb-0">{count} risultati <span className={"d-none d-md-inline"}>per la tua ricerca</span></h2>
                        </div>

                        <div className="col-auto">
                            <Dropdown className="dropdown-order">
                                <Dropdown.Toggle>
                                    Ordina per <b>{orderBy}</b>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => {setOrderBy("Ultimi Arrivi");setCurrentPage(0);}}>Ultimi Arrivi</Dropdown.Item>
                                    <Dropdown.Item onClick={() => {setOrderBy("Prezzo Crescente");setCurrentPage(0);}}>Prezzo Crescente</Dropdown.Item>
                                    <Dropdown.Item onClick={() => {setOrderBy("Prezzo Decrescente");setCurrentPage(0);}}>Prezzo Decrescente</Dropdown.Item>
                                    <Dropdown.Item onClick={() => {setOrderBy("Anno Crescente");setCurrentPage(0);}}>Anno Crescente</Dropdown.Item>
                                    <Dropdown.Item onClick={() => {setOrderBy("Anno Decrescente");setCurrentPage(0);}}>Anno Decrescente</Dropdown.Item>
                                    <Dropdown.Item onClick={() => {setOrderBy("Chilometraggio Crescente");setCurrentPage(0);}}>Chilometraggio Crescente</Dropdown.Item>
                                    <Dropdown.Item onClick={() => {setOrderBy("Chilometraggio Decrescente");setCurrentPage(0);}}>Chilometraggio Decrescente</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                        </div>

                        <div className="d-block d-md-none col-12">
                            {renderMobileFilterButton()}
                        </div>

                    </div>
                </div>
            </section>

            <FilterMobile renderFilters={() => renderFilters()} ref={mobileFilter}/>

            <section>
                <div className="container">
                    <div className="row" style={isMobile ? {marginBottom: -50} : null}>
                        <div className="d-none d-lg-block col-4">
                            {renderFilters()}
                        </div>
                        <div className="col-12 col-lg-8">
                            <div className="row">

                                {!pageLoaded ?
                                    <div className="col-12 d-flex">
                                        <span class="loader"></span>
                                    </div>
                                    : null
                                }

                                {carList.map((el, i) => {
                                    return (
                                        <div className="col-12 col-sm-6 col-xl-4 mb-4" key={"car-preview-" + i} >
                                            <CarPreview data={el} bookmarkFlag={checkBookmark(el.VclId)}/>
                                        </div>
                                    )
                                })}
                            </div>
                            {renderPagination()}
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
