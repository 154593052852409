import React, { useState} from 'react';
import Layout from '../../components/layout/layout';
import Hero from "../../components/hero/hero";
import Button from "../../components/button/button";

import styles from "./style.module.scss";
import { BASENAME } from "../../constant";
import ReactPlayer from 'react-player'



export default function Commercial() {
    const [play, setPlay] = useState(false);


    return (
        <Layout>
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle}>Noleggio veicoli commerciali</h1>
                <Hero
                    onlyBreadcrumb={true}
                    breadcrumb={[
                        { label: "Home", link: BASENAME },
                        { label: "Noleggio", link: null },
                        { label: "Veicoli commerciali", link: null }
                    ]}
                />
                
                <div className={styles.content}>
                    <div className="d-lg-none">
                        <img src="site_images/commercial_mobile.png" className="w-100" style={{ marginBottom: 24 }} />
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-5 pe-lg-5 d-none d-lg-block">
                            <img src="./site_images/commercial.png" className={styles.image} />
                        </div>
                        <div className="col-12 col-lg-7 ps-lg-5">
                            <div className={styles.contentText}>
                                <div className={styles.titleWithIcon}>
                                    <img src="site_images/Icon_elettrico.png" />
                                    <h2>Il mini-truck elettrico dalle dimensioni compatte</h2>
                                </div>
                                <div className={styles.paragraph}>
                                    OHM 35 BEV è un veicolo elettrico versatile con un telaio che consente diversi allestimenti, garantendo rigorosi standard di sicurezza. Può essere caricato con tre tipologie di ricarica: AC lenta 2kW (rete domestica), AC normale (caricabatteria a bordo 6,6 kW) e DC veloce (ccs combo 2 fino ad 80 kW).
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>autonomia di 200km</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>carico utile di 1,5t</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>ricarica fino al 90% in 40 minuti</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Area C e strisce blu, gratis</h2>
                                    </div>
                                </div>
                                <Button
                                    label={"Guarda la scheda tecnica"}
                                    variation={"tertiary"}
                                    style={{ marginBottom: 40, marginTop: 16 }}
                                    onClick={() => {
                                        window.open(
                                            'https://www.gruppomilanoauto.it/OHM_35_BEV_Brochure_GMA.pdf',
                                            '_blank'
                                        );
                                    }}
                                />
                                <div className={styles.miniTitle}>
                                    Noleggio e vendita
                                </div>
                                <div className={styles.paragraphBold}>
                                    OHM 35 BEV è disponibile per il noleggio a partire da €60/gg + iva e per la vendita secondo allestimento desiderato. Contattaci per maggiori informazioni e preventivi.
                                </div>
                                <Button
                                    label={"info@gruppomilanoauto.it"}
                                    variation={"4"}
                                    style={{ marginBottom: 40 }}
                                    iconRight={["far","envelope"]}
                                    onClick={() => window.open('mailto:info@gruppomilanoauto.it')}
                                    />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <ReactPlayer
                url='https://www.youtube.com/watch?v=z_NKR1ZSeBE'
                width='100%'
                controls={true}
                width='100%'
                height='700px'
            />
        </Layout>
    );
}

