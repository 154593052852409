import { API_URL} from "../constant"
 
export const ajaxGet = (url, config) => {
    console.log(API_URL + url)
    return fetch(API_URL + url, config, {
        method: "GET",
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log(error);
            //Catch Service Error
        });
};

export const ajaxPost = (url, formData) => {
    return fetch(API_URL + url, {
        method: "POST",
        body: formData,
        mode: 'cors',
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log(error);
            //Catch Service Error
        });
};