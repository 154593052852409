import React from 'react';
import styles from "./style.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Button(props) {
    let buttonStyle;
    let iswhatsapp;
    let iconSize = 24;
    switch (props.variation) {
        case "primary": buttonStyle = styles.primaryButton; break;
        case "secondary": buttonStyle = styles.secondaryButton; break;
        case "secondaryNoB": buttonStyle = styles.secondaryNoBorderButton; break;
        case "tertiary": buttonStyle = styles.tertiaryButton; break;
        case "tertiaryMobile": buttonStyle = styles.tertiaryMobileButton; break;
        case "quaternary": buttonStyle = styles.quaternaryButton; break;
        case "4": buttonStyle = styles.Button4; break;
        case "5": buttonStyle = styles.Button5; break;
        case "bookmark": buttonStyle = styles.bookmark; break;
        case "bookmarkSelected": buttonStyle = styles.bookmarkSelected; break;
        default: buttonStyle = styles.primaryButton
    }

    if (props.isWhatsapp) {
        iswhatsapp = true;
    } else {
        iswhatsapp = false;
    }
    
    if (!props.label) {
        buttonStyle=buttonStyle+" "+styles.iconOnly;
    }    
    buttonStyle += " " + styles.all;
    if (props.iconSize) {
        iconSize = props.iconSize;
    }

    return (
        iswhatsapp ? (
            <button className={buttonStyle} onClick={(e) => props.onClick ? props.onClick(e) : null} style={props.style}>
                {props.icon ? <FontAwesomeIcon icon={props.icon} style={{ padding: '0px', fontSize: iconSize}}/> : null}
                {/* {props.label ? <span style={{fontSize:props.labelSize}}>{props.label}</span> : null} */}
                {/* {props.iconRight ? <FontAwesomeIcon icon={props.iconRight} /> : null} */}
                {props.imgRight?<img/>:null}
            </button>
        ):(
            <button className={buttonStyle} onClick={(e) => props.onClick ? props.onClick(e) : null} style={props.style}>
                    {props.icon ? <FontAwesomeIcon icon={props.icon} style={{ padding: '0px', fontSize: iconSize}}/> : null}
                {props.label ? <span style={{fontSize:props.labelSize}}>{props.label}</span> : null}
                {props.iconRight ? <FontAwesomeIcon icon={props.iconRight} /> : null}
                {props.imgRight?<img/>:null}
            </button>
        )
    );
}
