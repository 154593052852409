export const toPrice = (n) => {
    return new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(n);
}

export const toFormat = (n) => {
    return new Intl.NumberFormat('it-IT', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(n);
}

// funzione per la verifica dell'esistenza del cookie 
export function getCookieForBookmark(cookieName) {
    var name = cookieName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');

    for (var i = 0; i < cookieArray.length; i++) {
        var cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length) === 'true';
        }
    }
    return null;
}

// Funzione per impostare il cookie con un array di codici ID
export function setCookieForBookmark(cookieName, booleanValue, expirationDays) {
    // Converti il valore booleano in una stringa ('true' o 'false')
    var stringValue = booleanValue ? 'true' : 'false';

    var d = new Date();
    d.setTime(d.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();

    // Imposta il cookie con il valore booleano
    document.cookie = cookieName + "=" + stringValue + "; " + expires + "; path=/";
}
