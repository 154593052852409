import React from 'react';
import Layout from '../../components/layout/layout';
import Hero from "../../components/hero/hero";
import Button from "../../components/button/button";

import styles from "./style.module.scss";
import { BASENAME } from "../../constant";



export default function California() {


    return (
        <Layout>
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle}>Noleggio VW California</h1>
                <Hero
                    onlyBreadcrumb={true}
                    breadcrumb={[
                        { label: "Home", link: BASENAME },
                        { label: "Noleggio", link: null },
                        { label: "California", link: null }
                    ]}
                />

                <div className={styles.content}>
                    <div className="d-lg-none">
                        <img src="site_images/california_mobile.png" className="w-100" style={{ marginBottom: 24 }} />
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 pe-lg-5 d-none d-lg-block">
                            <img src="./site_images/california.png" className={styles.image} />
                        </div>
                        <div className="col-12 col-lg-6 ps-lg-5 ">
                            <div className={styles.contentText}>
                                <div className={styles.paragraph}>
                                    Gruppo Milanoauto è partner di FreewayCamper.
                                    <br /><br />
                                    Puoi contare sul supporto di un nostro consulente presso la nostra sede di Cologno Monzese per godere di tutti i vantaggi del noleggio di FreewayCamper:
                                    <br /><br />
                                    Viaggia con FreewayCamper per avere la massima libertà e flessibilità! Oltre al facile processo di prenotazione con la totale trasparenza dei prezzi, non hai limiti di chilometraggio e l'attrezzatura da campeggio base è gratuita. Potrai così partire subito per la tua vacanza in campeggio. Ti offriremo un comodo appartamento su ruote, per ogni tua particolare esigenza.
                                    </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Prezzo equo garantito</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Noleggio a breve e lungo termine</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Cambio prenotazione flessibile</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Vasta scelta di campeggi</h2>
                                    </div>
                                </div>
                                <div className={styles.paragraph} style={{ marginTop: 24 }}>
                                    <b>Conosci già la soluzione Bulli + Van?</b>
                                    <br />
                                    Compatto e funzionale con due zone notte, una cucina, un accogliente salotto e molto spazio per i bagagli. Grazie al tetto a soffietto avrai molta libertà di movimento anche stando in piedi.
                                    <br /><br />
                                    <b>Prendi un appuntamento per conoscere dal vivo uno dei nostri VW Bulli Camper</b>
                                </div>

                                <Button
                                    label={"02 2540348"}
                                    variation={"4"}
                                    style={{ marginBottom: 40, marginTop: 16 }}
                                    iconRight={["fas", "phone"]}
                                    onClick={()=>window.open('tel:022540348')}
                                />

                                <div className={styles.littleTitle}>Massima Libertà e flessibilità</div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Chilometraggio illimitato</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Riprenotazione gratuita</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Attrezzatura di base inclusa</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>I cani sono i benvenuti</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>Nessun limite di autisti</h2>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className={styles.contentContainer} style={{ backgroundColor:"#D8DEE9", marginTop:56}}>
                <div className={styles.content}>
                    <div className="d-lg-none">
                        <img src="site_images/california_mobile_2.png" className="w-100" style={{ marginBottom: 24 }} />
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 ps-lg-5 ">
                            <div className={styles.contentText}>
                                <div className={styles.paragraph}>
                                    <b>Tutti i chilometri sono inclusi</b>
                                    <br />
                                    Non vogliamo che ti perda le destinazioni dei tuoi sogni. Ecco perché non abbiamo limiti di chilometraggio. Viaggia dove vuoi grazie al chilometraggio illimitato! (Le eccezioni si applicano solo per il noleggio in lungo termine)
                                    <br /><br />

                                    <b>Riprenotazione gratuita</b>
                                    <br />
                                    Se devi cancellare il tuo viaggio, non c'è problema. Se la cancellazione avviene entro 48 ore prima dell'inizio del viaggio, riceverai un buono per riprenotare gratuitamente in un periodo successivo nel 2023. Chiedi al nostro consulente le informazioni dettagliate sulla politica di cancellazione
                                    <br /><br />

                                    <b>Attrezzatura di base inclusa</b>
                                    <br />
                                    Sono finiti i giorni in cui devi preoccuparti di quali attrezzature e stoviglie portare con te. Con ogni nostro camper riceverai gratuitamente l'attrezzatura di base per il tuo viaggio. Il pacchetto include: il pacchetto cucina, due sgabelli e un tavolo, il pacchetto protezione Bronze, cunei drive-up, cavi elettrici e una consulenza approfondita in anticipo. Inoltre, è possibile prenotare attrezzature extra come il portabiciclette, toilette mobile e altri servizi.
                                    <br /><br />

                                    <b>I tuoi animali da compagnia sono i benvenuti</b>
                                    <br />
                                    I cani sono i benvenuti su tutti i nostri camper. Con una tariffa di servizio di 85 € puoi portare il tuo cane con te durante il viaggio. Nel pacchetto sono inclusi: una cuccia, una cintura di sicurezza, una protezione per il sedile e un ancoraggio per il pavimento. Il tuo cane avrà anche a disposizione una ciotola per il cibo.
                                    <br /><br />

                                    <b>Nessun limite di autisti</b>
                                    <br />
                                    Spesso è necessario più di un autista. Per questo motivo non abbiamo un limite di numero di autisti. L'unico requisito è che il conducente sia in possesso di una patente di guida valida da almeno un anno. È sufficiente portare le carte d'identità e le patenti di tutti i conducenti al momento del ritiro.
                                    <br /><br />

                                    <b>Contattaci per maggiori informazioni sul Noleggio Camper</b>
                                    <br />


                                </div>


                                <div className="d-flex justify-content-center justify-content-lg-start">
                                    <Button
                                        label={"02 2540348"}
                                        variation={"4"}
                                        style={{ marginBottom: 40, marginTop: 16, marginRight: 16 }}
                                        iconRight={["fas", "phone"]}
                                        onClick={()=>window.open('tel:022540348')}
                                    />
                                    <Button
                                        iconOnly={true}
                                        icon={["fab", "whatsapp"]}
                                        variation={"tertiary"}
                                        style={{ marginBottom: 40, marginTop: 16, width: 60 }}
                                        onClick={() => window.open('https://wa.me/message/6SDAOF2KJBZNE1')}
                                    />
                                </div>


                            </div>
                        </div>
                        <div className="col-12 col-lg-6 pe-lg-5 d-none d-lg-block">
                            <img src="./site_images/california_2.png" className={styles.image} />
                        </div>
                    </div>
                </div>

            </div>

        </Layout>
    );
}

