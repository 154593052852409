import React, {useState} from "react";
import Button from '../../components/button/button';
import { RIGHE_DESC } from "../../constant";
import {isMobile} from 'react-device-detect';

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    }
    return (
        <p className="text" style={isMobile ? {maxWidth:1320, margin: 'auto', fontSize:11} : {maxWidth:1320, margin: 'auto'}}>
            {isReadMore ? text.slice(0, RIGHE_DESC) : text}
            <span
                onClick={toggleReadMore}
                className="read-or-hide"
                style={{ color: "green" }}
            >
                {/* {isReadMore ?  */}
                <Button
                    label={isReadMore ? `Leggi di più +` : `Leggi di meno -`}
                    variation="primary"
                    // onClick={() => setSectionEquip[optSection] = !sectionsEquip[optSection]} 
                    onClick={() => setIsReadMore(!isReadMore)}
                    style={{ margin: 'auto',marginBottom: 30, marginTop: 30, width:210 }}
                />
            </span>
        </p>
    )
};

export default function Content (props) {
    return (
        <div style={{margin:'auto', width: 'auto'}}>
            <ReadMore>
                    {props.descriptionText}
            </ReadMore>
        </div>
    )
}