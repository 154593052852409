import React from 'react';
import Hero from '../../components/hero/hero';
import Layout from '../../components/layout/layout';
import styles from "./style.module.scss";
import { BASENAME } from "../../constant";
import {isMobile} from 'react-device-detect';

export default function About() {


    return (
        <Layout>
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle}>Il Gruppo</h1>
                <Hero
                    onlyBreadcrumb={true}
                    breadcrumb={[
                        { label: "Home", link: BASENAME },
                        { label: "Il Gruppo", link: null }
                    ]}

                />
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.content}>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <h2 className="mb-1">Siamo specializzati</h2>
                                <p>
                                    nella vendita di <b>Audi, Volkswagen e Porsche.</b><br />
                                    Proponiamo una vasta gamma di vetture <b>multibrand</b> su scala nazionale.
                            </p>
                                <img className="w-100" src="site_images/about-image-1.png" />
                            </div>

                            <div className="col-12 col-lg-6">
                                <div className="row flex-lg-wrap-reverse flex-wrap">
                                    <div className="col-12 mt-5">
                                        <p>
                                            <b>Il nostro team</b> è in grado di fornire un <b>servizio a 360 gradi</b>, con soluzioni <b>finanziarie</b> e <b>assicurative</b> personalizzate, leasing, noleggio, e assistenza post vendita.
                                    </p>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-6">
                                                <img src="site_images/about-image-2.jpg" className="w-100" style={{ borderRadius: '40px' }} />
                                            </div>
                                            <div className="col-6">
                                                <img src="site_images/about-image-3.jpg" className="w-100" style={{ borderRadius: '40px' }} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>

            <div className={styles.contentContainer}>
                <div className={styles.content}>
                        <h2 className="text-center mb-5">Le società del Gruppo</h2>
                        <div className="row">
                            <div className="col-12 col-lg-4">
                                <h3>Rondinella Auto Solutions (ML Automotive srl)</h3>
                                <p>
                                    La società primaria che ha dato vita al brand Gruppo Milanoauto. Con la sua sede a Cologno Monzese è da oltre 30 anni punto di riferimento locale per i marchi Volkswagen e Audi.
                                    Grazie alla qualità organizzativa del Gruppo Volkswagen ha sperimentato e sviluppato il Metodo dell’Importazione Diretta ottimizzando i costi di acquisto e costituendo un’offerta multimarca.
                                    La contraddistingue una notevole offerta di veicoli di gamma medio-alta con allestimenti spesso esclusivi.
                                    Prodotti: Nuovo, Km 0 e Semestrali, Usato certificato
                                    Servizi: Soluzioni finanziarie e assicurative personalizzate, Leasing, Noleggio, e assistenza post vendita.
                                </p>
                            </div>

                            <div className="col-12 col-lg-4">
                                <h3>Viola Auto Solutions srl</h3>
                                <p>
                                    Società acquisita da ML Automotive srl al fine di estendersi a livello territoriale con la sede di Cormano che con i suoi 4000 metri quadri è in grado di offrire due Showroom e un’area dedicata per l’Assistenza.
                            </p>
                                <h3>F.lli Viola srl</h3>
                                <p>
                                    Società specializzata nell'assistenza di veicoli. Il team è in grado di soddisfare anche i clienti più esigenti grazie alla strumentazione all'avanguardia e alla formazione del Gruppo Volkswagen. Alcuni tecnici sono specializzati nel restauro di vetture d'epoca.
                            </p>
                            </div>

                            <div className="col-12 col-lg-4" style={isMobile ? { marginBottom: -33 } : null}>
                                <h3>Autocarrozzeria Plus srl</h3>
                                <p>
                                    L'Autocarrozzeria ha sede a Cormano ed è gestita da Stefano Berton. La famiglia Berton offre da oltre 70 anni un servizio carrozzeria particolarmente preciso e completo.
                            </p>
                            </div>
                        </div>
                </div>
            </div>
        </Layout>
    );
}

