import React from 'react';
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";

export default function BrandButton(props) {
    const navigate = useNavigate();

    return (
        <button className={styles.button} onClick={() => navigate('/veicoli', {
            state: {
                brand: props.name.toUpperCase(),
            }
        })}
        >
            <img src={"site_images/brands/" + props.name + ".png"} />
            <h4>{props.name}</h4>
        </button>

    );
}
