import React, { useState, useEffect } from 'react';
import Hero from '../../components/hero/hero';
import Layout from '../../components/layout/layout';
import BrandButton from '../../components/brandButton/brandButton';
import Button from '../../components/button/button';
import {isMobile} from 'react-device-detect';

import styles from "./style.module.scss";
import { BASENAME } from "../../constant";


export default function Brands() {
    return (
        <Layout>
            <Hero
                image={"bg_hero_brands.png"}
                title={"I nostri Brand"}
                subtitle={isMobile ? null :"Siamo specializzati nella vendita di Audi, Volkswagen e Porsche. Proponiamo una vasta gamma di vetture multibrand su scala nazionale."}
                noLineHeight={true}
                mini={true}
                breadcrumb={[
                    { label: "Home", link: BASENAME },
                    { label: "I nostri Brand", link: null }
                ]}

            />
            <section>
                <div className="container pt-5" style={isMobile ? {marginBottom:-75, marginTop:-32} : null}>
                    <div className="row text-center">
                        <div className="col">
                            <BrandButton name="audi" />
                        </div>
                        <div className="col">
                            <BrandButton name="volkswagen" />
                        </div>
                        <div className="col">
                            <BrandButton name="porsche" />
                        </div>
                        <div className="col">
                            <BrandButton name="seat" />
                        </div>
                        <div className="col">
                            <BrandButton name="skoda" />
                        </div>
                        <div className="col">
                            <BrandButton name="cupra" />
                        </div>
                        <div className="col">
                            <BrandButton name="ford" />
                        </div>
                        <div className="col">
                            <BrandButton name="jaguar" />
                        </div>
                        <div className="col">
                            <BrandButton name="jeep" />
                        </div>
                        <div className="col">
                            <BrandButton name="kia" />
                        </div>
                        <div className="col">
                            <BrandButton name="land rover" />
                        </div>
                        <div className="col">
                            <BrandButton name="nissan" />
                        </div>
                        <div className="col">
                            <BrandButton name="mercedes" />
                        </div>
                        <div className="col">
                            <BrandButton name="opel" />
                        </div>
                        <div className="col">
                            <BrandButton name="volvo" />
                        </div>
                    </div>
            </div>
                {/*
                <div className={styles.buttonContainer}>
                <Button label={"Carica altri brand"} iconRight={"plus"} variation="tertiary" />
            </div>
            */}
            </section>

        </Layout>


    );
}

