import React, { useState, useEffect } from 'react';
import Hero from '../../components/hero/hero';
import Layout from '../../components/layout/layout';
import CarPreview from "../../components/carPreview/carPreview";
import { BASENAME } from "../../constant";
import {isMobile} from 'react-device-detect';

import styles from "./style.module.scss";
import { ajaxGet, ajaxPost } from "../../helper/ajax";


export default function Porsche() {
    const [porscheList, setporscheList] = useState([]);

    const renderCheckList = (val) => {
        return (
            <div className={styles.checkList}>
                <img style={{marginLeft:25}} src="site_images/svg/Check.svg"/>
                <h4>{val}</h4>
            </div>
        )
    }



    useEffect(() => {
        let formdata = new FormData();
        formdata.append("brand", "PORSCHE");
        ajaxPost("getVclList.php", formdata).then((response) => {
            if (response.code == "100") {
                setporscheList(response.message.car)
            }
        });
    }, [])


    return (
        <Layout>
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle}>Porsche</h1>
            <Hero
                onlyBreadcrumb={true}
                breadcrumb={[
                    { label: "Home", link: BASENAME },
                    { label: "Porsche", link: null }
                ]}
                />
            </div>
            <div className={styles.contentContainer} style={{ backgroundColor:"#D8DEE9"}}>
                <div className={styles.content}>
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-4 text-lg-end">
                            <h2>Scopri il mondo Porsche di Gruppo Milanoauto</h2>
                        </div>
                        <div className="col-12 col-lg-8">
                            <p>
                                Nel corso degli anni abbiamo maturato un’importante esperienza con diversi marchi del Gruppo Volkswagen, fra cui il brand Porsche. Grazie a dei rapporti intensi e stabili con alcuni concessionari e commercianti di vetture Porsche in tutta Europa, siamo in grado di offrire modelli e allestimenti in grado di soddisfare anche i clienti più esigenti. Determinate vetture le troverai esclusivamente nel mondo Porsche di Gruppo Milanoauto, senza alcuna possibilità di effettuare paragoni, in termini di offerta e tempi di consegna.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.contentContainer} style={{ backgroundColor: "#D8DEE9", position:"relative" }}>

                <div className={styles.content}>
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6" >
                            <img src="site_images/porsche_image.png" className={styles.porscheImage}/>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="row align-items-center">
                                <div className="col-5 col-md-4">
                                    <img src="site_images/brands/porsche.png" className={styles.porscheLogo}  />
                                </div>
                                <div className="col-7 col-md-8">
                                    <div className={styles.checkListContainer}>
                                        {renderCheckList("Showroom dedicato")}
                                        {renderCheckList("Personalizzazione post-acquisto")}
                                        {renderCheckList("Consegna in 20-60 giorni")}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.contentContainer}>
            <div className={styles.content}>
                    <h2>{porscheList.length} veicoli <blue>Porsche</blue> sono disponibili</h2>

                    <div className="row">
                        {porscheList.map((el, i) => {
                            return (
                                <div className={"col-12 col-md-4 col-lg-3 my-3"}>
                                    <CarPreview data={el} key={"car-preview-" + i} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    );
}

