import React from 'react';
import Layout from '../../components/layout/layout';
import Hero from "../../components/hero/hero";
import Button from "../../components/button/button";

import MyCarousel from '../../components/carousel/carousel';

import styles from "./style.module.scss";
import { BASENAME } from "../../constant";



export default function CarBody() {
    let responsive = {
        desktop: {
            breakpoint: { max: 5000, min: 1024 },
            items: 1

        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    };

    const css = `
    .react-multi-carousel-dot-list {
        margin-bottom:5px;
    }
`

    return (
        <Layout>
            <style>{css}</style>
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle}>Carrozzeria</h1>
                <Hero
                    onlyBreadcrumb={true}
                    breadcrumb={[
                        { label: "Home", link: BASENAME },
                        { label: "Assistenza", link: null },
                        { label: "Carrozzeria", link: null }
                    ]}
                />

                <div className={styles.content}>
                    <div className="d-lg-none">
                        <img src="site_images/carrozzeria_new_logo_mobile.png" className="w-100" style={{ marginBottom: 24 }} />
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-7 pe-lg-5">
                            <div className={styles.contentText}>
                                <div className={styles.paragraph}>
La nostra carrozzeria di <b>Cormano</b> cura l’estetica della tua auto ed opera in perfetta sinergia con l’officina. Forniamo supporto completo in seguito ad un sinistro, con la gestione delle pratiche assicurative, cessione del credito risarcitorio, soccorso stradale e vettura sostitutiva. 
                                    </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>riparazione carrozzeria</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>lucidatura e rigenerazione fari</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>levabolli</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>soccorso stradale</h2>
                                    </div>
                                </div>
                                <div className={styles.checkmarksContainer}>
                                    <img src="site_images/icon/icon_check.png" />
                                    <div>
                                        <h2>vettura sostitutiva</h2>
                                    </div>
                                </div>
                                <div className={styles.paragraph} style={{ marginBottom: 0, marginTop: 20 }}>
                                    <b>Prenota ora</b> un appuntamento
                                </div>
                                <div className="d-flex justify-content-center justify-content-lg-start">
                                    <Button
                                        label={"02 2540348"}
                                        variation={"4"}
                                        style={{ marginBottom: 40, marginTop: 16, marginRight: 16 }}
                                        iconRight={["fas", "phone"]}
                                        onClick={()=>window.open('tel:022540348')}
                                    />
                                    <Button
                                        iconOnly={true}
                                        icon={["fab", "whatsapp"]}
                                        variation={"tertiary"}
                                        style={{ marginBottom: 40, marginTop: 16, width: 60 }}
                                        onClick={() => window.open('https://wa.me/message/6SDAOF2KJBZNE1')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5 ps-lg-5 d-none d-lg-block">
                            <img src="./site_images/carrozzeria_new_logo.png" className={styles.image} />
                        </div>
                    </div>
                </div>

            </div>

            <div className={styles.contentContainer} style={{ backgroundColor: "#D8DEE9", marginTop: 56 }}>
                <div className={styles.content}>
                    <div className={"d-block d-md-none"}>
                        <MyCarousel
                            responsive={responsive}
                            arrows={false}
                            showDots={false}
                            showDotsTablet={true}
                            showDotsMobile={true}
                        >
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/Icon_Import-car.png" />
                                    <h2>Trasparenza e garanzia</h2>
                                </div>
                                <p>
                                    Rilasciamo una <b>fattura dettagliata</b> con l’elenco delle attività svolte, il codice dei ricambi e le ore di manodopera.<br />
                                    Sui nostri interventi hai una <b>garanzia di 12 mesi</b>.
                                </p>
                            </div>
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/Icon_value_2.png" />
                                    <h2>Servizio di ritiro e consegna</h2>
                                </div>
                                <p>
                                    Puoi fare comodamente il tagliando da casa o dall'ufficio, devi solo lasciarci le chiavi.<br />
                                    <b>Ci pensiamo noi a ritirare e riconsegnare l’auto</b> dove desideri. Il servizio è disponibile <b>su richiesta ed entro i 10km</b> dalla nostra sede.
                                </p>
                            </div>

                        </MyCarousel>

                    </div>

                    <div className="row justify-content-center d-none d-md-flex">
                        <div className="col-12 col-md-4">
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/Icon_Import-car.png" />
                                    <h2>Trasparenza e garanzia</h2>
                                </div>
                                <p>
                                    Rilasciamo una <b>fattura dettagliata</b> con l’elenco delle attività svolte, il codice dei ricambi e le ore di manodopera.<br />
                                    Sui nostri interventi hai una <b>garanzia di 12 mesi</b>.
                                    </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className={styles.serviceContent}>
                                <div className={styles.serviceTitleWithImage}>
                                    <img src="site_images/icon/Icon_value_2.png" />
                                    <h2>Servizio di ritiro e consegna</h2>
                                </div>
                                <p>
                                    Puoi fare comodamente il tagliando da casa o dall'ufficio, devi solo lasciarci le chiavi.<br />
                                    <b>Ci pensiamo noi a ritirare e riconsegnare l’auto</b> dove desideri. Il servizio è disponibile <b>su richiesta ed entro i 10km</b> dalla nostra sede.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </Layout>
    );
}

