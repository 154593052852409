import React, {forwardRef, useState, useRef, useEffect, useImperativeHandle} from 'react';
import emailjs from '@emailjs/browser';
import Iubenda from 'react-iubenda-policy'

import styles from "./style.module.scss";
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import Button from "../button/button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import markerLogo from '../../img/icon-sede.png'
import {isMobile} from 'react-device-detect';
import { ajaxGet, ajaxPost } from "../../helper/ajax";

const containerStyle = {
    width: '100%',
    height: '100%',
    minHeight:'500px'
};

const center = {
    lat: 45.47,
    lng: 9.15
};

const marker1 = {
    lat: 45.54124660267275,
    lng: 9.288067900000001
}

const marker2 = {
    lat: 45.545721264819726,
    lng: 9.164600770312765
}

export default function ContactForm (props) {
    // Id del link alla privacy policy
    const myPolicy = 29122716

    const form = useRef();


    function handleErrorCheckbox() {
        if (areCheckboxAllTrue(mandatoryTerms)) {
            setErrorMessage(false);
            return false;
        } else {
            setErrorMessage(true);
            return true;
        }
    }

    const [sending, setSending] = useState(false);


    const sendEmail = (e) => {
        e.preventDefault();
        if (sending) { return }

        setSending(true);


        let formdata = new FormData();
        formdata.append("name", user_name);
        formdata.append("surname", user_surname);
        formdata.append("phone", user_telephone);
        formdata.append("email", user_email);
        formdata.append("subject", user_reqType);
        formdata.append("message", user_message);
        formdata.append("hidden_data", document.getElementById('hidden_data').value);
        ajaxPost("sendMail.php", formdata).then((response) => {
            setSending(false);

            if (response.code == "100") {
                setSuccessMessage(true)
                setErrorMessage(false)

                setName("");
                setSurname("");
                setTelephone("");
                setEmail("");
                setMessage("");
                setReqType("");
            } else {
                setErrorMessage(true)
                setSuccessMessage(false)
            }
        });



        /*
        emailjs
        .sendForm('service_qv028gz', 'template_u42dv5g', "#myform", {
            publicKey: 'xa-Lt3NEEoYbCFzZt',
        })
        .then(
            (response) => {
                console.log('SUCCESS!', response.status, response.text);
            },
            (error) => {
                console.log('FAILED...', error.text);
            },
        );*/
    };


    // funzione per modificare il valore dei checkbox
    const handleChangeButtonOptional = (optCheckboxPos) => {
        // Creare una copia dell'array
        const newArray = mandatoryTerms.slice();

        // Modificare il primo elemento della copia in base al parametro
        newArray[optCheckboxPos] = !newArray[optCheckboxPos];

        // Aggiornare lo stato con la nuova copia
        setMandatoryTerms(newArray);
    };

    // Funzione per verificare che tutti i checkbox siano stati spuntati
    const areCheckboxAllTrue = (array) => {
        return array.every((element) => element === true);
    };

    const [user_name, setName] = useState("");
    const [user_surname, setSurname] = useState("");
    const [user_telephone, setTelephone] = useState("");
    const [user_email, setEmail] = useState("");
    const [user_reqType, setReqType] = useState("");
    const [user_message, setMessage] = useState("");
    // hook per la gestione dei 3 checkbox
    const [mandatoryTerms, setMandatoryTerms] = useState([false, false, false]);
    // hook per la gestione del messaggio di errore
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    //
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update windowWidth when the window is resized
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup: remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function checkIpad(screenWidth) {
        if (screenWidth < 995)
            return true
        return false
    }

    return (
        <section className="pb-5">
            <div className={isMobile ? "container" : checkIpad(windowWidth) ? "container-fluid" : "container"} >
                {/* style={isMobile ? null : {marginLeft: 50, marginRight: 50}}> */}
                <div className={styles.container} style={{ marginLeft: 10, marginRight: 10 }}>
                    <div className="row m-0">

                        <div className="col-12 p-0" onClick={(e) => e.stopPropagation()}>
                            <div className={styles.contactFormContainer} style={isMobile ? { marginTop: -12 } : null}>
                                <div className={styles.title} id="contact-form">Contattaci</div>
                                <p className={styles.description} style={isMobile ? { marginBottom: 32 } : null}>Per ricevere maggiori informazioni sulle nostre auto e servizi</p>
                                <form ref={form} onSubmit={sendEmail} id='myform'>
                                    <div className={"row"}>
                                        <input type="hidden" id="hidden_data" onChange={(val) => console.log(val)} />
                                        <div className={"col-12 col-md-6"}>
                                            <input placeholder="Nome" value={user_name} name="user_name" type='text' required="required" onChange={txt => setName(txt.target.value)} style={isMobile ? { marginBottom: 16, outline: 'none' } : { outline: 'none' }} />
                                        </div>
                                        <div className={"col-12 col-md-6"}>
                                            <input placeholder="Cognome" value={user_surname} name="user_surname" required="required" type='text' onChange={txt => setSurname(txt.target.value)} style={isMobile ? { marginBottom: 16, outline: 'none' } : { outline: 'none' }} />
                                        </div>
                                        <div className={"col-12 col-md-6"}>
                                            <input placeholder="Numero di telefono" value={user_telephone} name="user_telephone" required="required" type='tel' onChange={txt => setTelephone(txt.target.value)} style={isMobile ? { marginBottom: 16, outline: 'none' } : { outline: 'none' }} />
                                        </div>
                                        <div className={"col-12 col-md-6"}>
                                            <input placeholder="E-mail" value={user_email} name="user_email" type='email' required="required" onChange={txt => setEmail(txt.target.value)} style={isMobile ? { marginBottom: 16, outline: 'none' } : { outline: 'none' }} />
                                        </div>
                                        <div className={"col-12"}>
                                            <select required value={user_reqType} name="user_reqType" onChange={txt => { setReqType(txt.target.value) }} style={isMobile ? { marginBottom: 16 } : null}>
                                                <option value="" >Tipologia di richiesta</option>
                                                <option >Acquisto</option>
                                                <option>Noleggio</option>
                                                <option>Service</option>
                                                <option>Carrozzeria</option>
                                                <option>Altro</option>
                                            </select>
                                        </div>
                                        <div className={"col-12"}>
                                            <textarea value={user_message} placeholder="Come possiamo aiutarti?" rows="5" name="user_message" type='text' onChange={txt => setMessage(txt.target.value)} style={{ outline: 'none' }}></textarea>
                                        </div>
                                        <div className={"col-12 mb-2 d-flex"} style={isMobile ? { marginTop: -10 } : null}>
                                            <input required="required" type="checkbox" id="contact-form-terms" value="1" onClick={handleChangeButtonOptional} />
                                            <label htmlFor="contact-form-terms" style={{fontSize:12}}>
                                                Acconsento al trattamento dei miei dati e dichiaro di aver preso visione della <a href="/privacy-policy">Privacy Policy</a>
                                            </label>
                                        </div>
                                        {
                                            errorMessage ? (
                                                <label style={{ color: 'red', fontWeight: 500 }}>Errore durante l'invio</label>
                                            ) : null
                                        }
                                        {
                                            successMessage ? (
                                                <label style={{ color: 'green', fontWeight: 500 }}>Email inviata con successo</label>
                                            ) : null
                                        }
                                        {
                                            isMobile ? (
                                                <Button label="Invia richiesta" variation="tertiary" type="submit" />
                                            ) : (
                                                    <Button label="Invia richiesta" variation="tertiary" iconRight="arrow-right" type="submit" />
                                                )
                                        }
                                        <div className={"row"} style={{ marginTop: 20, justifyContent:"space-between" }}>
                                            <div className={"col-12 col-lg-auto"}>
                                                <div className={styles.contactsContainer}>
                                                    <img src="../site_images/svg/Phone_blu.svg" width={24} height={24} fill="blue" style={{ textAlign: 'right', marginRight: 10 }} />
                                                    {/* <FontAwesomeIcon icon={["fas", "phone"]} className={styles.contactsContainerIcon} /> */}
                                                    <div>
                                                        <p className={styles.contactsValue}><a href="tel:+39022540348" className={"unstyleLink"}>02 2540348</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-12 col-lg-auto"}>
                                                <div className={styles.contactsContainer}>
                                                    <FontAwesomeIcon icon={["far", "envelope"]} className={styles.contactsContainerIcon} />
                                                    <div>
                                                        <p className={styles.contactsValue}><a href="mailto:info@gruppomilanoauto.it" className={"unstyleLink"}>info@gruppomilanoauto.it</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-12 col-lg-auto"}>
                                                <div className={styles.contactsContainer}>
                                                    <FontAwesomeIcon icon={["fab", "whatsapp"]} className={styles.contactsContainerIcon} />
                                                    <div>
                                                        <p className={styles.contactsValue}><a href="https://wa.me/message/6SDAOF2KJBZNE1" className={"unstyleLink"}>02 2534990</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
